import {RoutesNamesEnum} from '~/app/router.options';
import {useAppToast} from '~/stores/appToast';

export interface UserInfo {
  id: string;
  age: number;
  avgRating: number;
  bio: string;
  city: string;
  firstName: string;
  lastName: string;
  photoUrl: string | null;
  registeredSince: string;
  categoryIds: string[] | null;
}

export const useUserStore = defineStore('userStore', () => {
  const {showApiErrorToast} = useAppToast();

  const currentUser = ref<UserInfo>();
  const route = useRoute();

  function fetchServiceProvider(serviceProviderId: string) {
    return useGetJobApi(`/service-providers/{serviceProviderId}`, {
      method: 'GET',
      path: {
        serviceProviderId,
      },
      onResponseError: showApiErrorToast,
    });
  }

  function fetchUser(userId: string) {
    return useGetJobApi(`/users/{userId}`, {
      method: 'GET',
      path: {
        userId,
      },
      onResponseError: showApiErrorToast,
    });
  }

  function setCurrentUser(user: UserInfo) {
    currentUser.value = user;
  }

  function getCurrentUser() {
    return currentUser.value;
  }

  function fetchUserPhone(userId: string) {
    if (route.name === RoutesNamesEnum.ServiceProviderUserProfile) {
      return useGetJobApi('/service-providers/{serviceProviderId}/phone', {
        method: 'GET',
        path: {
          serviceProviderId: userId,
        },
        onResponseError: showApiErrorToast,
      });
    }

    return useGetJobApi('/users/{userId}/phone', {
      method: 'GET',
      path: {
        userId,
      },
      onResponseError: showApiErrorToast,
    });
  }

  function $reset() {
    currentUser.value = undefined;
  }

  return {
    fetchServiceProvider,
    fetchUser,
    fetchUserPhone,
    setCurrentUser,
    getCurrentUser,
    $reset,
  };
});
